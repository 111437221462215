import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-el-paso-texas.png'
import image0 from "../../images/cities/scale-model-of-tooth-and-veil-in-el-paso-texas.png"
import image1 from "../../images/cities/scale-model-of-chihuahuan-desert-gardens-in-el-paso-texas.png"
import image2 from "../../images/cities/scale-model-of-el-paso-zoo-in-el-paso-texas.png"
import image3 from "../../images/cities/scale-model-of-castner-range-pre-national-monument-in-el-paso-texas.png"
import image4 from "../../images/cities/scale-model-of-el-paso-museum-of-art-in-el-paso-texas.png"
import image5 from "../../images/cities/scale-model-of-ron-coleman-trailhead-in-el-paso-texas.png"
import image6 from "../../images/cities/scale-model-of-san-jacinto-plaza-in-el-paso-texas.png"
import image7 from "../../images/cities/scale-model-of-el-paso-museum-of-history-in-el-paso-texas.png"
import image8 from "../../images/cities/scale-model-of-wigwam-museum-in-el-paso-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='El Paso'
            state='Texas'
            image={image}
            lat='31.7618778'
            lon='-106.4850217'
            attractions={ [{"name": "Tooth and Veil", "vicinity": "820 N Mesa St Suite #200, El Paso", "types": ["art_gallery", "clothing_store", "finance", "store", "point_of_interest", "establishment"], "lat": 31.7639584, "lng": -106.49127390000001}, {"name": "Chihuahuan Desert Gardens", "vicinity": "El Paso", "types": ["museum", "park", "university", "point_of_interest", "establishment"], "lat": 31.7692017, "lng": -106.50623139999999}, {"name": "El Paso Zoo", "vicinity": "4001 E Paisano Dr, El Paso", "types": ["zoo", "point_of_interest", "establishment"], "lat": 31.7691897, "lng": -106.4438879}, {"name": "Castner Range Pre-National Monument", "vicinity": "1201 N Mesa St, El Paso", "types": ["point_of_interest", "establishment"], "lat": 31.7661356, "lng": -106.49408440000002}, {"name": "El Paso Museum of Art", "vicinity": "1 Arts Festival Plaza, El Paso", "types": ["museum", "point_of_interest", "establishment"], "lat": 31.7585876, "lng": -106.4902692}, {"name": "Ron Coleman Trailhead", "vicinity": "McKelligon Canyon Rd, El Paso", "types": ["park", "point_of_interest", "establishment"], "lat": 31.8437234, "lng": -106.49133689999996}, {"name": "San Jacinto Plaza", "vicinity": "114 W Mills Ave, El Paso", "types": ["park", "point_of_interest", "establishment"], "lat": 31.7596192, "lng": -106.48852249999999}, {"name": "El Paso Museum of History", "vicinity": "510 N Santa Fe St, El Paso", "types": ["museum", "point_of_interest", "establishment"], "lat": 31.760088, "lng": -106.4915603}, {"name": "Wigwam Museum", "vicinity": "108 E San Antonio Ave, El Paso", "types": ["museum", "point_of_interest", "establishment"], "lat": 31.7577523, "lng": -106.48830550000002}] }
            attractionImages={ {"Tooth and Veil":image0,"Chihuahuan Desert Gardens":image1,"El Paso Zoo":image2,"Castner Range Pre-National Monument":image3,"El Paso Museum of Art":image4,"Ron Coleman Trailhead":image5,"San Jacinto Plaza":image6,"El Paso Museum of History":image7,"Wigwam Museum":image8,} }
       />);
  }
}